import React from 'react'

import Layout from '@c/layout'
import SEO from '@c/seo'
import { graphql } from 'gatsby'

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title={data.settings.title} />
    <h1>{data.settings.title}</h1>
    <div dangerouslySetInnerHTML={{ __html: data.settings.description }}></div>
  </Layout>
)

export default NotFoundPage

export const query = graphql`
  query NotFoundPageQuery {
    settings: datoCmsSiteSetting(locale: { eq: "en" }) {
      title: notFound404PageTitle
      description: notFound404PageDescription
    }
  }
`
